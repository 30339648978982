<template>
  <div>
    <iframe src="https://datasight-dot-tsi-dev.appspot.com/" title="Data Sight (DEV)" style="width:100%;height: 100%;"></iframe>
  </div>
</template>

<script lang="js">
import {
  accountExists,
  emailVerified,
  isAuthorized
} from '@fusion/auth'

export default {
  name: 'DataSight',
  mixins: [isAuthorized, accountExists, emailVerified]
}
</script>

<style scoped lang="scss"></style>
