var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c("fusion-reactivate-account", {
        attrs: { open: _vm.dialogs.reactivateAccount },
        on: { close: this.closeReactivateAccount },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "main-banner" }, [
      _c("h1", [_vm._v("Explore the Future of Software with Us!")]),
      _c("p", [
        _vm._v(
          "Join our journey in crafting innovative applications that empower our community."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "introduction" }, [
      _c("h2", [_vm._v("Partner with Us in Developing High-Impact Software")]),
      _c("p", [
        _vm._v(
          " In a world where environmental health and safety are increasingly at the forefront of public consciousness, TSI stands committed to making a difference. Our suite of beta applications is not just about technology; it's about forging a path towards a safer, healthier planet. We understand the intricate relationship between the environment and human well-being. That's why our software solutions are designed to address the complex challenges of environmental health and safety. From tracking pollution levels to enhancing workplace safety, our applications aim to empower individuals, communities, and organizations to make informed decisions and take meaningful actions. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "community-engagement" }, [
      _c("h2", [_vm._v("Your Feedback Shapes Our Future")]),
      _c("p", [
        _vm._v(
          "Your feedback on these applications will do more than shape their development; it will contribute to a broader mission of creating sustainable and safe environments for everyone. Join us on this journey to harness the power of technology for the greater good of our planet and its inhabitants. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }