import { render, staticRenderFns } from "./index.vue?vue&type=template&id=eba7f4e8&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eba7f4e8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/buildp/jenkins/workspace/fusion-saas-betasite/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eba7f4e8')) {
      api.createRecord('eba7f4e8', component.options)
    } else {
      api.reload('eba7f4e8', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=eba7f4e8&scoped=true&", function () {
      api.rerender('eba7f4e8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DataSight/index.vue"
export default component.exports