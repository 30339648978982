<template>
  <div id="app">
    <!-- Main Banner -->
    <section class="main-banner">
      <h1>Explore the Future of Software with Us!</h1>
      <p>Join our journey in crafting innovative applications that empower our community.</p>
    </section>

    <!-- Introduction Section -->
    <section class="introduction">
      <h2>Partner with Us in Developing High-Impact Software</h2>
      <p>
        In a world where environmental health and safety are increasingly at the forefront of public consciousness,
        TSI stands committed to making a difference. Our suite of beta applications is not just about technology;
        it's about forging a path towards a safer, healthier planet. We understand the intricate relationship between
        the environment and human well-being. That's why our software solutions are designed to address the complex
        challenges of environmental health and safety. From tracking pollution levels to enhancing workplace safety, our
        applications aim to empower individuals,
        communities, and organizations to make informed decisions and take meaningful actions.
      </p>
    </section>

    <!-- Community Engagement Section -->
    <section class="community-engagement">
      <h2>Your Feedback Shapes Our Future</h2>
      <p>Your feedback on these applications will do more than shape their development;
        it will contribute to a broader mission of creating sustainable and safe environments for everyone.
        Join us on this journey to harness the power of technology for the greater good of our planet and its inhabitants.
      </p>
    </section>

    <fusion-reactivate-account
      :open="dialogs.reactivateAccount"
      @close="this.closeReactivateAccount"
    ></fusion-reactivate-account>
  </div>
</template>

<script lang="js">
import {
  accountExists,
  emailVerified,
  isAuthorized
} from '@fusion/auth'
import { mixins } from '@fusion/saas-components'

export default {
  name: 'HomePage',
  mixins: [isAuthorized, accountExists, emailVerified, mixins.reactivateAccount]
}
</script>

<style scoped lang="scss">
#app {
  padding: 25px;
}
</style>
